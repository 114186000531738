import React from "react"

import styles from "./intro.module.scss"

const Intro = () => (
  <div className={styles.intro}>
    <div className="wrapper">
      <div className="grid">
        <div className="grid-child grid--push-start-2 grid-child--5-col-l grid-child--6-col-s">
          <h3 className="heading h3">
            At Fiftyone3 you can make connections as well as friends. We meet,
            we chat, we may even play a game or two.
          </h3>
        </div>
        <div className="grid-child grid--push-start-8 grid-child--4-col-l grid-child--6-col-s">
          <p className="body">
            Fiftyone3 started with a vision to make networking events more
            enjoyable. We wanted our guests to feel relaxed and happy to chat.
          </p>
          <p className="body">
            We ditched elevator pitches or presentations and said goodbye to
            name badges. We steered clear of bacon baps and stewed filter
            coffee. We have no dress code and welcome everyone and anyone,
            creative or professional, business owner or someone starting out.
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default Intro

import React from "react"
import InstagramFeed from "./instagramFeed"

import styles from "./instagramBlock.module.scss"
import ButtonLink from "../atoms/buttonLink"

const InstagramBlock = () => (
  <div className={styles.instagramBlock}>
    <div className="wrapper">
      <div className="grid">
        <div className="grid-child grid--push-start-4 grid-child--6-col-l grid-child--6-col-s">
          <h5 className={`${styles.instagramBlockTitleWrap} heading h3`}>
            Check out our Instagram to get a flavour for Fiftyone3 events
          </h5>
        </div>
        <div className="grid-child grid--push-start-3 grid-child--8-col-l grid-child--6-col-s">
          <InstagramFeed />
        </div>
        <div
          className="grid-child grid--push-start-3 grid-child--8-col-l grid-child--6-col-s"
          style={{ textAlign: "center", padding: "3em 0" }}
        >
          <ButtonLink
            text="Follow us on Instagram"
            link="https://www.instagram.com/fiftyone3/"
            instagram={true}
          />
        </div>
      </div>
    </div>
  </div>
)

export default InstagramBlock

import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/organisms/hero"
import Intro from "../components/organisms/intro"
import Why from "../components/organisms/why"
import InstagramBlock from "../components/organisms/instagramBlock"
import MailingList from "../components/organisms/mailingList"
import Sponsors from "../components/organisms/sponsors"

import "../components/pages/index.scss"

class IndexPage extends React.Component {
	// temp fix to Link comp not reset scroll position
	componentDidUpdate() {
		window.scrollTo(0, 0)
	}

	scrollToSignup() {
		document.getElementById("mailing-list").scrollIntoView({
			behavior: "smooth",
		})
	}

	render() {
		const event = this.props.pageContext.event
		let lede =
			"Food, drinks & entertainment with like-minded people, Fiftyone3’s events are designed to make you laugh while you make contacts."
		if (event === null) {
			lede =
				"We're currently working on the next event!<br />To find out more please subscribe to our mailing list."
		}

		return (
			<Layout>
				<SEO
					title="Business & young professional networking in Cardiff"
					keywords={[`Networking`, `Young professionals`, `Cardiff`]}
				/>
				<Hero
					heading="Business networking for Cardiff’s social networkers."
					lede={lede}
					style={{ zIndex: "1" }}
					btnClickEvent={this.scrollToSignup}
					event={event}
				/>

				<div className="index" style={{ zIndex: "2", position: "relative" }}>
					<Intro />

					<Why />

					<InstagramBlock />

					<MailingList />
				</div>
				<Sponsors />
			</Layout>
		)
	}
}

export default IndexPage

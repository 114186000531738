import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Img from 'gatsby-image';

import styles from './instagramFeed.module.scss';
import '../../styles/base.scss';

export default () => (
	<StaticQuery
		query={graphql`
			query {
				allInstaNode(limit: 6, sort: { fields: timestamp, order: DESC }) {
					edges {
						node {
							original
							caption
							id
							localFile {
								childImageSharp {
									fluid(maxWidth: 700, maxHeight: 700) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
					}
				}
			}
		`}
		render={(data) => (
			<div className={styles.instagramFeed}>
				{data.allInstaNode.edges.map(({ node }, index) => (
					<div className={styles.instagramFeedItem} key={index}>
						<a
							key={index}
							className={styles.instagramFeedImage}
							href={`https://instagram.com/p/${node.id}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							<Img fluid={node.localFile.childImageSharp.fluid} fadeIn={true} />
							<span className={styles.visuallyhidden}>Instagram image</span>
						</a>
					</div>
				))}
			</div>
		)}
	/>
);

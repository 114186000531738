import React from "react"

import Button from "../atoms/button"

import styles from "./featuredBlock.module.scss"
import mailStyles from "./mailingList.module.scss"
import formStyles from "../../styles/quarks/_forms.scss"

const MailingList = () => (
  <div id="mailing-list" className={mailStyles.mailingList}>
    <div className="wrapper wrapper--no-pad-s">
      <div className="grid">
        <div className="grid-child grid--push-start-1 grid-child--12-col-l grid-child--6-col-s">
          <div
            className={styles.featuredBlock + " " + styles.featuredBlockBlue}
          >
            <div
              className={styles.featuredBlockImage + " " + styles.shapeOverBlue}
            >
              <div className={styles.featuredBlockTitle}>
                <h3 className="heading h1" style={{ gridColumnEnd: "span 5", gridColumnStart: '2' }}>Stay in the loop</h3>
              </div>
            </div>
            <div className={styles.featuredBlockContent}>
              <div id="mc_embed_signup" style={{ paddingTop: "0.5em" }}>
                <form
                  action="https://fiftyone3.us14.list-manage.com/subscribe/post?u=8c5cf1d0ee8da8b8d78015b33&amp;id=11cc2aa60f"
                  method="post"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  className={formStyles.form}
                  target="_blank"
                  noValidate
                >
                  <div id="mc_embed_signup_scroll">
                    <div className="form__group">
                      <label
                        htmlFor="mce-EMAIL"
                        className="form__label heading h6"
                      >
                        Email Address
                      </label>
                      <input
                        type="email"
                        // value=""
                        name="EMAIL"
                        className="required email form__field"
                        id="mce-EMAIL"
                      />
                    </div>
                    <div className="form__group">
                      <label
                        htmlFor="mce-FNAME"
                        className="form__label heading h6"
                      >
                        First Name
                      </label>
                      <input
                        type="text"
                        // value=""
                        name="FNAME"
                        className=" form__field"
                        id="mce-FNAME"
                      />
                    </div>
                    <div className="form__group">
                      <label
                        htmlFor="mce-LNAME"
                        className="form__label heading h6"
                      >
                        Last Name
                      </label>
                      <input
                        type="text"
                        // value=""
                        name="LNAME"
                        className=" form__field"
                        id="mce-LNAME"
                      />
                    </div>
                    <div
                      id="mergeRow-gdpr"
                      className="mergeRow gdpr-mergeRow content__gdprBlock mc-field-group"
                    >
                      <div className="form__gdpr">
                        <p className="form__gdpr--legal body">
                          Fiftyone3 will use the information you provide to get
                          in touch with you about updates and marketing.
                        </p>
                        <fieldset
                          className="mc_fieldset gdprRequired mc-field-group"
                          name="interestgroup_field"
                        >
                          <label
                            className="checkbox subfield"
                            htmlFor="gdpr_16305"
                          >
                            <input
                              type="checkbox"
                              id="gdpr_16305"
                              name="gdpr[16305]"
                              value="Y"
                              className="av-checkbox "
                            />
                            <span className="form__gdpr--legal form__gdpr--legal-checkbox body">
                              This is okay with me.
                            </span>{" "}
                          </label>
                        </fieldset>
                      </div>
                    </div>
                    <div id="mce-responses" className="clear">
                      <div
                        className="response"
                        id="mce-error-response"
                        style={{ display: "none" }}
                      />
                      <div
                        className="response"
                        id="mce-success-response"
                        style={{ display: "none" }}
                      />
                    </div>
                    {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
                    <div
                      style={{ position: "absolute", left: "-5000px" }}
                      aria-hidden="true"
                    >
                      <input
                        type="text"
                        name="b_8c5cf1d0ee8da8b8d78015b33_11cc2aa60f"
                        tabIndex="-1"
                        // value=""
                      />
                    </div>
                    <div className="clear">
                      {/* <input
                      type="submit"
                      // value="Subscribe"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      className="Button"
                    /> */}
                      <Button
                        text="Subscribe"
                        hex="#ffffff"
                        color="#141919"
                        type="submit"
                        value="Subscribe"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        width="100%"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default MailingList

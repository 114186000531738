import React from "react"

import styles from "./featuredBlock.module.scss"
import hexFill from "../../images/hexagon-fill.svg"

const Why = () => (
  <div className="Why">
    <div className="wrapper wrapper--no-pad-s">
      <div className="grid">
        <div className="grid-child grid--push-start-1 grid-child--12-col-l grid-child--6-col-s">
          <div className={styles.featuredBlock}>
            <div
              className={styles.featuredBlockImage + " " + styles.shapeOverPink}
            >
              <div className={styles.featuredBlockTitle}>
                <h3
                  className="heading h1"
                  style={{ gridColumnEnd: "span 5", gridColumnStart: "2" }}
                >
                  Why come to our events?
                </h3>
              </div>
            </div>
            <div className={styles.featuredBlockContent}>
              <div className="text" style={{ gridColumnEnd: "span 6" }}>
                <p className="body">
                  Our events offer the chance to meet other like minded
                  professionals in a fun and informal environment.
                </p>
                <p className="body">
                  You’ll find us in awesome venues across Cardiff where you get
                  free drinks, delicious food and some fun and games.
                </p>
                <ul
                  className="body list"
                  style={{ width: "100%", listStyle: `url(${hexFill}` }}
                >
                  <li>
                    <span>Entertainment</span>
                  </li>
                  <li>
                    <span>Networking</span>
                  </li>
                  <li>
                    <span>Food & Drink</span>
                  </li>
                  <li>
                    <span>All for only £20</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Why
